import { render, staticRenderFns } from "./AddressesDetails.vue?vue&type=template&id=99edc25a&scoped=true&"
import script from "./AddressesDetails.vue?vue&type=script&lang=ts&"
export * from "./AddressesDetails.vue?vue&type=script&lang=ts&"
import style0 from "./AddressesDetails.vue?vue&type=style&index=0&id=99edc25a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99edc25a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonLoader: require('/var/www/components/SkeletonLoader/index.vue').default,UserAddressDetails: require('/var/www/components/UserAddressDetails.vue').default})
